import React, { useState } from 'react';
import TwitterDashboard from '../components/dashboards/TwitterDashboard.js';
import TelegramDashboard from '../components/dashboards/TelegramDashboard.js';
import './css/ResearchScreen.css';

function ResearchScreen() {
    const [selectedDashboard, setSelectedDashboard] = useState(null);

    const handleButtonClick = (dashboard) => {
        setSelectedDashboard(dashboard);
    };

    return (
        <div className="research-screen">
            <h2>Welcome to the Research Screen</h2>
            <div className="button-group">
                <button
                    className={`button ${selectedDashboard === 'twitter' ? 'selected' : ''}`}
                    onClick={() => handleButtonClick('twitter')}
                >
                    Twitter
                </button>
                <button
                    className={`button ${selectedDashboard === 'telegram' ? 'selected' : ''}`}
                    onClick={() => handleButtonClick('telegram')}
                >
                    Telegram
                </button>
            </div>
            <div className="dashboard-content">
                {selectedDashboard === 'twitter' && <TwitterDashboard />}
                {selectedDashboard === 'telegram' && <TelegramDashboard />}
            </div>
        </div>
    );
}

export default ResearchScreen;
