import React, { useState } from 'react';
import './css/TwitterFilterinputs.css';

const TwitterFilterInputs = ({ filters, handleChange, handleAddUsername, handleRemoveUsername }) => {
    const [usernameInput, setUsernameInput] = useState("");

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && usernameInput.trim()) {
            handleAddUsername(usernameInput.trim());
            setUsernameInput(""); // Clear input after adding
        }
    };

    return (
        <>
            <label>
                User Posted:
                <input
                    type="text"
                    value={usernameInput}
                    onChange={(e) => setUsernameInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter Username and press Enter"
                />
            </label>
            <div>
                {filters.user_posted.map((user, index) => (
                    <button key={index} className="keyword-button" onClick={() => handleRemoveUsername(user)}>
                    {user}
                        <span className="remove-x">   ×</span>
                        </button>
                ))}
            </div>
            <label>
                Description:
                <input
                    type="text"
                    name="description"
                    value={filters.description}
                    onChange={handleChange}
                    placeholder="Enter Description"
                />
            </label>

            <label>
                Start Date:
                <input
                    type="date"
                    name="startDate"
                    value={filters.startDate}
                    onChange={handleChange}
                />
            </label>

            <label>
                End Date:
                <input
                    type="date"
                    name="endDate"
                    value={filters.endDate}
                    onChange={handleChange}
                />
            </label>

            <label>
                Photos:
                <input
                    type="text"
                    name="photos"
                    value={filters.photos}
                    onChange={handleChange}
                    placeholder="Enter Photos"
                />
            </label>

            <label>
                Videos:
                <input
                    type="text"
                    name="videos"
                    value={filters.videos}
                    onChange={handleChange}
                    placeholder="Enter Videos"
                />
            </label>

            <label>
                URL:
                <input
                    type="text"
                    name="url"
                    value={filters.url}
                    onChange={handleChange}
                    placeholder="Enter URL"
                />
            </label>

            <label>
                Quoted Post:
                <input
                    type="text"
                    name="quoted_post"
                    value={filters.quoted_post}
                    onChange={handleChange}
                    placeholder="Enter Quoted Post"
                />
            </label>

            <label>
                Tagged Users:
                <input
                    type="text"
                    name="tagged_users"
                    value={filters.tagged_users}
                    onChange={handleChange}
                    placeholder="Enter Tagged Users"
                />
            </label>

            <label>
                Replies:
                <input
                    type="number"
                    name="replies"
                    value={filters.replies}
                    onChange={handleChange}
                    placeholder="Enter Replies Count"
                />
            </label>

            <label>
                Reposts:
                <input
                    type="number"
                    name="reposts"
                    value={filters.reposts}
                    onChange={handleChange}
                    placeholder="Enter Reposts Count"
                />
            </label>

            <label>
                Likes:
                <input
                    type="number"
                    name="likes"
                    value={filters.likes}
                    onChange={handleChange}
                    placeholder="Enter Likes Count"
                />
            </label>

            <label>
                Views:
                <input
                    type="number"
                    name="views"
                    value={filters.views}
                    onChange={handleChange}
                    placeholder="Enter Views Count"
                />
            </label>

            <label>
                External URL:
                <input
                    type="text"
                    name="external_url"
                    value={filters.external_url}
                    onChange={handleChange}
                    placeholder="Enter External URL"
                />
            </label>

            <label>
                Hashtags:
                <input
                    type="text"
                    name="hashtags"
                    value={filters.hashtags}
                    onChange={handleChange}
                    placeholder="Enter Hashtags"
                />
            </label>

            {/* Other filter inputs remain the same */}
        </>
    );
};

export default TwitterFilterInputs;
