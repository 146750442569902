import React, { useState, useEffect } from 'react';
import { csvVariableDownload, loadCsvFile } from '../../utils/streanCsv';
import { sliceTelegramCsv } from '../../utils/dataManipulation';
import './css/FilterModalTelegram.css';
import TelegramFilterInputs from '../inputs/TelegramFilterInputs';

const CHANNEL_NAMES = [
    'newsil_tme', 'Yediotnews', 'tzenzora', 'gazaalannet', 
    'israel_news_telegram', 'hotnews1', 'newsdeskisrael', 
    'newss', 'haravot_barzel_no_size', 'News_24_0_7', 
    'News_cabinet_news', 'zmanemmet', 'qassambrigades', 
    'admma_news', 'Realtimesecurity1'
];

const FilterModalTelegram = ({ onToggle }) => {
    const csvFileUrl = `${process.env.PUBLIC_URL}/telegram_v1.csv`;

    const [csvData, setCsvData] = useState([]);
    const [filters, setFilters] = useState({
        startDate: '',
        endDate: '',
        channels: [],
        keywords: [],  // Ensure this is an array
    });
    const [loading, setLoading] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        const initCsvData = async () => {
            try {
                const data = await loadCsvFile(csvFileUrl);
                setCsvData(data);
            } catch (error) {
                console.error("Error loading CSV data:", error);
            } finally {
                setLoading(false);
            }
        };

        initCsvData();
    }, [csvFileUrl]);

    const onApply = () => {
        const filteredCsv = sliceTelegramCsv(csvData, filters);
        csvVariableDownload(filteredCsv);
        onToggle();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const handleAddKeyword = (keyword) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            keywords: [...prevFilters.keywords, keyword], // Keep as an array
        }));
    };

    const handleRemoveKeyword = (key) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            keywords: prevFilters.keywords.filter(keyword => keyword !== key),
        }));
    };

    const toggleChannel = (channel) => {
        setFilters((prevFilters) => {
            const isSelected = prevFilters.channels.includes(channel);
            const newChannels = isSelected 
                ? prevFilters.channels.filter(c => c !== channel)
                : [...prevFilters.channels, channel];
            return { ...prevFilters, channels: newChannels };
        });
    };

    const toggleSelectAll = () => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            channels: filters.channels.length === CHANNEL_NAMES.length ? [] : CHANNEL_NAMES,
        }));
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Apply Filters</h2>
                <TelegramFilterInputs 
                    filters={filters} 
                    handleChange={handleChange}
                    toggleSelectAll={toggleSelectAll}
                    toggleChannel={toggleChannel}
                    CHANNEL_NAMES={CHANNEL_NAMES}
                    dropdownOpen={dropdownOpen}
                    setDropdownOpen={setDropdownOpen}
                    handleAddKeyword={handleAddKeyword}  // Pass the method
                    handleRemoveKeyword={handleRemoveKeyword} // Pass remove method
                />

                <button onClick={onApply} disabled={loading}>
                    {loading ? "Loading..." : "Apply Filters"}
                </button>
                <button onClick={onToggle}>Close</button>
            </div>
        </div>
    );
};

export default FilterModalTelegram;
