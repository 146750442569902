
import React, { useState, useEffect } from 'react';
import { csvVariableDownload, loadCsvFile } from '../../utils/streanCsv';
import { sliceTwitterCsv } from '../../utils/dataManipulation';
import './css/FilterModalTwitter.css';
import TwitterFilterInputs from '../inputs/TwitterFilterInputs';

const FilterModalTwitter = ({ onToggle }) => {
    const csvFileUrl = `${process.env.PUBLIC_URL}/twitter_v1.csv`;

    const [csvData, setCsvData] = useState([]);
    const [filters, setFilters] = useState({
        user_posted: [],  // Change to array for multiple usernames
        description: '',
        startDate: '',
        endDate: '',
        photos: '',
        videos: '',
        url: '',
        quoted_post: '',
        tagged_users: '',
        replies: '',
        reposts: '',
        likes: '',
        views: '',
        external_url: '',
        hashtags: '',
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const initCsvData = async () => {
            try {
                const data = await loadCsvFile(csvFileUrl);
                setCsvData(data);
            } catch (error) {
                console.error("Error loading CSV data:", error);
            } finally {
                setLoading(false);
            }
        };

        initCsvData();
    }, [csvFileUrl]);

    const onApply = () => {
        let filteredCsv = sliceTwitterCsv(csvData, filters);
        console.log(`Filtered Rows: ${filteredCsv.length}`);
        csvVariableDownload(filteredCsv);
        onToggle();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const handleAddUsername = (username) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            user_posted: [...prevFilters.user_posted, username],
        }));
    };

    const handleRemoveUsername = (username) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            user_posted: prevFilters.user_posted.filter(user => user !== username),
        }));
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Apply Filters</h2>
                
                <TwitterFilterInputs
                    filters={filters}
                    handleChange={handleChange}
                    handleAddUsername={handleAddUsername}
                    handleRemoveUsername={handleRemoveUsername}
                />

                <button onClick={onApply} disabled={loading}>
                    {loading ? "Loading..." : "Apply Filters"}
                </button>
                <button onClick={onToggle}>Close</button>
            </div>
        </div>
    );
};

export default FilterModalTwitter;
