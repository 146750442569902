import {useState,React} from 'react';
import DownloadButton from '../buttons/DownloadButton';  // Import the DownloadButton component
import FilterModalTelegram from '../popups/FilterModalTelegram';  

function TelegramDashboard() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const csvFileUrl = `${process.env.PUBLIC_URL}/telegram_v1.csv`;
    
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div>
            <button onClick={toggleModal}>Filter By</button>
            <DownloadButton
                label="Download Telegram Data"
                fileUrl={csvFileUrl}
            />
            {isModalOpen && <FilterModalTelegram onToggle={toggleModal} />}
        </div>
    );
}

export default TelegramDashboard;
