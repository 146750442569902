import {useState,React} from 'react';

import DownloadButton from '../buttons/DownloadButton';  // Import the DownloadButton component
// import FilterModalTelegram from '../popups/FilterModalTelegram';  
import FilterModalTwitter from '../popups/FilterModalTwitter';
function TwitterDashboard() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const csvFileUrl = `${process.env.PUBLIC_URL}/twitter_v1.csv`;
    
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div>
            <button onClick={toggleModal}>Filter By</button>
            <DownloadButton
                label="Download Twitter Data"
                fileUrl={csvFileUrl}
            />
            {isModalOpen && <FilterModalTwitter onToggle={toggleModal} />}
        </div>
    );
}

export default TwitterDashboard;
