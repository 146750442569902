export const sliceTelegramCsv = (csvData, filters) => {
    // Log the CSV columns (keys of the first row)
    if (csvData.length > 0) {
        console.log("CSV Columns:", Object.keys(csvData[0]));
    }

    const filteredData = csvData.filter((row) => {
        const rowDateTime = new Date(`${row.date}T${row.time}`);
        const startDate = filters.startDate ? new Date(filters.startDate) : null;
        const endDate = filters.endDate ? new Date(filters.endDate) : null;
        const betweenDates = (!startDate || rowDateTime >= startDate) && (!endDate || rowDateTime <= endDate)

        const channelNameKey = "channel name";
        const channelExists = channelNameKey in row;
        const channelMatches = (!filters.channels.length) ||( channelExists && filters.channels.includes(row[channelNameKey].trim()));

        const content = row.content;
        const belongToChannels = (!content || !filters.keywords.length || filters.keywords.some(keyword => content.includes(keyword)))

        return (
            belongToChannels &&
            betweenDates &&
            channelMatches 
        );
    });

    return filteredData;
};



export const sliceTwitterCsv = (csvData, filters) => {
    return csvData.filter((row) => {
        const rowDate = new Date(row.date_posted);
        const startDate = filters.startDate ? new Date(filters.startDate) : null;
        const endDate = filters.endDate ? new Date(filters.endDate) : null;
        const betweenDates = (!startDate || rowDate >= startDate) && (!endDate || rowDate <= endDate) 
        const includesUserPoster =  (!filters.user_posted.length|| filters.user_posted.some(user => row.user_posted.includes(user))) 

        return (
            betweenDates &&
            includesUserPoster &&
            (!filters.description || row.description.toLowerCase().includes(filters.description.toLowerCase())) &&
            (!filters.photos || row.photos.includes(filters.photos)) &&
            (!filters.videos || row.videos.includes(filters.videos)) &&
            (!filters.url || row.url.includes(filters.url)) &&
            (!filters.quoted_post || row.quoted_post.includes(filters.quoted_post)) &&
            (!filters.tagged_users || (row.tagged_users && row.tagged_users.some(user => filters.tagged_users.includes(user)))) &&
            (!filters.replies || row.replies === parseInt(filters.replies)) &&
            (!filters.reposts || row.reposts === parseInt(filters.reposts)) &&
            (!filters.likes || row.likes === parseInt(filters.likes)) &&
            (!filters.views || row.views === parseInt(filters.views)) &&
            (!filters.external_url || row.external_url.includes(filters.external_url)) &&
            (!filters.hashtags || (row.hashtags && row.hashtags.some(tag => filters.hashtags.includes(tag))))
        );
    });
};
// ש